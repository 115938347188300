:root {
    --pf-v5--global--FontFamily--heading--sans-serif: "RedHatDisplay", "Overpass", Helvetica, sans-serif !important;
    --pf-v5--global--FontFamily--sans-serif: "RedHatText", "Overpass", Helvetica, sans-serif !important;
    --pf-v5--global--FontFamily--redhatfont--heading--sans-serif: "RedHatDisplay", "Overpass", Helvetica, sans-serif !important;
    --pf-v5--global--FontFamily--redhatfont--sans-serif: "RedHatText", "Overpass", Helvetica, sans-serif !important;
    .pf-c-button {
        --pf-v5--c-button--FontWeight: var(--pf-v5--global--FontWeight--normal);
    }
    .pf-c-spinner {
        &.pf-m-xl {
            --pf-v5--c-spinner--Color: rgba(3, 3, 3, .55);
            --pf-v5--c-spinner--Width: 2.5rem;
            --pf-v5--c-spinner--Height: 2.5rem;
            --pf-v5--c-spinner--m-xl--diameter: 2.5rem;
        }
        &.on-dark {
            --pf-v5--c-spinner--Color: #ffffff;
        }
    }
    .link-disabled {
        color: var(--pf-v5--global--disabled-color--100);
        cursor: not-allowed;
    }
}
