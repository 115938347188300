.certify-card {
    opacity: 0;
    animation: fadeIn 1s forwards;
    background: var(--Blue---pf-v5-global--palette--blue-50, #E7F1FA);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    &__img {
        padding: 1.5rem 0 1.5rem 1.5rem;
    }

    &__title {
        --pf-v5-c-card__title-text--FontSize: 24px;
    }

    &__title-icon {
        padding-right: 1.5rem;
    }
    @keyframes fadeIn {
        to {
            opacity: 1;
        }
    }
}
