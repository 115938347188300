.partner-subscriptions{
    &__info-icon{
        color: var(--pf-v5-global--info-color--100);
    }
    &__check-circle-icon {
     color: var(--pf-v5-global--success-color--100);
    }
    &__warning-icon{
        color: var(--pf-v5-global--warning-color--100);
    }
    .pf-v5-c-toolbar{
        border-bottom: 1px solid var(--pf-v5-global--Color--light-300);
    }
    &__disabled-row {
        color: var(--pf-v5-global--disabled-color--100);
    }
}
