.error-page-four-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .error-main {
    text-align: center;
    &--hero-gradient {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(../../assets/overlay.svg), url(../../assets/index-left.svg),
      url(../../assets/index-right.svg), url(../../assets/gradient.svg);
      background-repeat: no-repeat;
      background-position: center, left, right, center;
      background-size: cover, auto 100%, auto 100%, cover;
      @media screen and (min-width: 768px) and (max-width: 992px) {
        background-position: center, left -6rem center, right -6rem center, center;
      }
      @media screen and (min-width: 576px) and (max-width: 768px) {
        background-position: center, left -12rem center, right -12rem center, center;
      }
      @media screen and (max-width: 576px) {
        background-position: center, left -18rem center, right -18rem center, center;
      }
      @media (min-width: 992px) {
        min-height: 460px;
      }
      .error-main-masthead {
        color: white;
        text-align: center;
        height: auto;
        width: 60%;
        &--content {
          &--header {
            font-size: 3rem;
            font-weight: 300;
            margin-bottom: 1rem;
            line-height: 1;
            @media (max-width: 768px) {
              line-height: 1;
            }
          }
          &--para {
            font-size: 1.25rem;
            margin-bottom: 2rem 0;
          }
        }
      }
    }
    &--relative-position--cards {
      @media (min-width: 992px) {
        position: relative;
        top: -160px;
        min-height: 407px;
      }
    }
    &--main {
      display: inline-block;
      background-color: #f0f0f0;
      margin-top: 2rem;
      min-height: 315px;
      width: 80%;
      align-self: center;
      &--header {
        align-self: center;
        text-align: center;
        margin-top: 3rem !important;
        font-size: 1.5rem;
        font-weight: 600;
      }
      &--link-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        section {
          width: 33%;
          padding: 3rem;
          text-align: center;
          h3 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
          }
        }
      }
      &--feedback {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          font-size: 1.25rem;
        }
        &--link {
          margin-top: 1.5rem;
        }
        &--arrow {
          padding-left: 1rem;
          vertical-align: middle;
        }
      }
    }
  }
}
