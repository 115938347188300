@import '../../cssUtils/variables';
.hardware-partner-onboarding {
    --pf-v5-c-page__main--ZIndex: 0 !important;
    min-height: 100vh;
    &__main-page-section {
        .wizard-main {
            margin-left: 1em;
            .page-description {
                color: $color-raven;
                width: 38em;
            }
            .button-group {
                margin-top: 3em;
            }
            .empty-state {
                width: 50em;
            }
            .helper-text {
                margin-top: 1.5em;
                margin-bottom: 1em;
                width: 38em;
            }
        }
    }
}
