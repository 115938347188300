.authenticated-dashboard{
    &__img_wrapper {
        margin: auto;
        padding: 5%;
    }
    &__quarter-card-grid-item{
        height: 20em;
    }
    &__quarter-card-grid-item-with-sales-card{
        height: 22em;
    }
    &__card {
        text-decoration: none;
    }
    &__card__top-variant {
        cursor: pointer;
    }
    &__img {
        height: 45px;
    }
    &__group-padding {
        margin-top: 48px
    }
}
