@import 'cssUtils/_variables.scss';
@import 'cssUtils/_globals.scss';
@import "~@patternfly/react-styles/css/utilities/Accessibility/accessibility.css";
.app {
  min-height: 100vh;
  background-color: var(--pf-v5-global--BackgroundColor--200);
  .a {
    text-decoration: none !important;
  }
}
