.dashboard-header {
    --pf-v5-c-banner--BackgroundColor: #212427 !important;
    --pf-v5-c-banner--PaddingTop: 3.5em !important;
    --pf-v5-c-banner--PaddingBottom: 2.5em !important;
    --pf-v5-c-banner--PaddingLeft: 3.5em !important;
    background-image: url('../../../assets/Partner_portal_banner_bg.png');
    background-repeat: no-repeat;
    background-position: right;
    &__name{
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__subtitle {
        --pf-v5-c-content--h2--MarginTop: 0 !important;
    }
}
