.enrolled-partner-training {
    &__table {
        &.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > *:first-child {
            --pf-v5-c-table--cell--PaddingLeft: 0;
        }
        &.pf-v5-c-table.pf-m-compact tr:where(.pf-v5-c-table__tr):not(.pf-v5-c-table__expandable-row) > *:last-child {
            --pf-v5-c-table--cell--PaddingRight: 0;
        }
        &__pie-chart-content {
            &__percentage-text {
                width: 7em;
            }
        }
    }
}
