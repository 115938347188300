.products {
    min-height: 16em;
    &__heading {
        margin-block-end: 1.5rem !important;
    }
    &__card {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25), 0 0 1px 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;
        &__description {
            --pf-v5-c-content--h4--MarginTop: 0;
            --pf-v5-c-content--MarginBottom: 0;
            max-width: 200px;

            &__type {
                font-size: 14px;
            }
            &__cert-info {
                font-size: 14px;
                color: #6A6E73;
            }
        }
        &__type-icon {
            width: 36px;
        }
        &__footer {
            &__product-status {
                margin-top: 0.75rem;
            }
        }
    }
}
